import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
export default function Header(props) {
  return (
    <header>
      <nav className={`navbar navbar-dark bg-dark navbar-expand-md fixed-top`}>
        <span
          className="menuToggle"
          onClick={() => {
            props.sidebarToggle();
          }}
        >
          <i className={`fa fa-${props.headerCLs ? "times" : "bars"}`}></i>
        </span>
        <Link to="/" className="navbar-brand">
          Admin Panel
        </Link>
        <div className={`collapse navbar-collapse`} id="navbarCollapse"></div>
        <Dropdown>
          <Dropdown.Toggle variant="thm" id="dropdown-basic">
            <i className={"fa fa-user"}></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <Link to="/settings" style={{ textDecoration: "none" }}>
                <button className="dropdown-item">Settings</button>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <button
                className="dropdown-item"
                onClick={() => {
                  props.logout();
                }}
              >
                Logout
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </nav>
    </header>
  );
}
