export default function swDev(){

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');
       
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
       
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }
      
      function determinServiceKey(){
        const vapidPublicKey = 'BJthRQ5myDgc7OSXzPCMftGw-n16F7zQBEN7EUD6XxcfTTvrLGWSIG7y_JxiWtVlCFua0S8MTB5rPziBqNx1qIo';
        return urlBase64ToUint8Array(vapidPublicKey);
      }
      

    let swURL=`${process.env.PUBLIC_URL}/sw.js`
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swURL).then((res)=>{
            console.warn("register", res);
            return res.pushManager.getSubscription().then((subscription)=>{
                return res.pushManager.subscribe({
                    userVisibleOnly:true,
                    applicationServerKey:determinServiceKey()
                })
            })
        }).catch((error)=>{
            console.warn("error", error)
        });
    }
}