let ROOT_URL = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  console.log(1);
  ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/a'
} else {
  // TODO: Server API URL
  ROOT_URL = "https://api.dadropoff.com/api/v1/a";
  console.log(2);
}
ROOT_URL = "https://api.dadropoff.com/api/v1/a";
// ROOT_URL = "https://doapi.alphonic.net.in/api/v1/a";
// ROOT_URL = "https://api.dadropoff.com/api/v1/a"; //live api added
export default ROOT_URL;
