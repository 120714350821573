import React, { Component, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  BrowserRouter,
} from "react-router-dom";
import apiFunc from "../services/api";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Header from "../components/Header";
import "react-pro-sidebar/dist/css/styles.css";
// import "./App.css";
import "../deep.css";

const HomePage = lazy(() => import("./home"));
const SettingsPage = lazy(() => import("./settings/settings"));
const AddUserPage = lazy(() => import("./admin-users/add-view"));
const UsersPage = lazy(() => import("./admin-users/list"));
const VendorAccountsTransactions = lazy(() =>
  import("./account/VendorAccountTransaction")
);
const AdminAccountsTransactions = lazy(() =>
  import("./account/AdminAccountTransactions")
);
const DriverAccountsTransactions = lazy(() =>
  import("./account/DriverAccountTransactions")
);
const VendorCategoryPage = lazy(() => import("./Vendors-category/list"));
const AddVendorsCategoryPage = lazy(() =>
  import("./Vendors-category/add-view")
);
const CustomerAccounts = lazy(() => import("./account/CustomerAccounts"));
const AddProductsPage = lazy(() => import("./Products-category/add-view"));
const ProductsPage = lazy(() => import("./Products-category/list"));
const VendorPage = lazy(() => import("./vendor/list"));
const AddVendorsPage = lazy(() => import("./vendor/add-view"));
const Vendor_Products = lazy(() => import("./vendor-products/list"));
const Add_View_Vendor_Products = lazy(() =>
  import("./vendor-products/add-view")
);
const Product_Stock = lazy(() => import("./vendor-products/stock"));
const Product_Stock_Update = lazy(() =>
  import("./vendor-products/UpdateStock")
);
const Terms = lazy(() => import("./Terms/terms"));
const Privacy = lazy(() => import("./Terms/privacy"));
const Cancellation = lazy(() => import("./Terms/cancellation"));
const AddImagebyVendorProducts = lazy(() =>
  import("./vendor-products/ProfileImage")
);

const AddProfileImageByVendor = lazy(() => import("./vendor/profileImage"));
const AddVendorAddress = lazy(() => import("./vendor/address"));
const AddCoverImageByVendor = lazy(() => import("./vendor/coverImage"));
const VendorStoreTimings = lazy(() => import("./vendor/storeTimings"));
const VendorWebPasswordChange = lazy(() => import("./vendor/webPassword"));
const VendorAppPasswordChange = lazy(() => import("./vendor/appPassword"));
const AdminAccount = lazy(() => import("./account/AdminAccount"));
const FAQ = lazy(() => import("./FAQ's/list"));
const FaqView = lazy(() => import("./FAQ's/view"));
const FaqAdd = lazy(() => import("./FAQ's/add"));

const AdminOrders = lazy(() => import("./admin-orders/list"));
const AddViewAdminOrders = lazy(() => import("./admin-orders/view"));

const AdminDrivers = lazy(() => import("./Driver/list"));
const DriversAddView = lazy(() => import("./Driver/add-view"));

const AdminCustomers = lazy(() => import("./Customer/list"));
const CustomersAddView = lazy(() => import("./Customer/add-view"));
const CouponListPage = lazy(() => import("./coupons/couponList"));
const Addcoupon = lazy(() => import("./coupons/AddViewCoupon"));
const VendorAccount = lazy(() => import("./account/VendorAccount"));
const DriverAccounts = lazy(() => import("./account/DriverAccounts"));
const HelpDeskListPage = lazy(() => import("./Help-Desk/list"));
const HelpDeskAddView = lazy(() => import("./Help-Desk/add-view"));

export default class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCLs: false,
    };
    this.sidebarToggle = this.sidebarToggle.bind(this);
  }

  logout = () => {
    console.log("clicked");
    this.props.setAuth();
  };
  sidebarToggle = () => {
    this.setState({
      sidebarCLs: !this.state.sidebarCLs,
    });
    apiFunc.reFreshToken().then(
      (res) => {
        localStorage.setItem("token", res.data.token);
      },
      (err) => {}
    );
  };
  checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;

    return pathname === "/";
  };
  render() {
    return (
      <Router>
        <div>
          <Header
            headerCLs={this.state.sidebarCLs}
            logout={() => {
              this.logout();
            }}
            sidebarToggle={() => {
              this.sidebarToggle();
            }}
          />
          <div className="routerView">
            <div
              className={`sidebarBackdrop ${
                this.state.sidebarCLs ? "activeBackdrp" : ""
              }`}
              onClick={() => {
                this.sidebarToggle();
              }}
            ></div>
            <ProSidebar
              collapsed={false}
              className={`${this.state.sidebarCLs ? "sidebarActive" : ""}`}
            >
              <Menu iconShape="round">
                <MenuItem>
                  <NavLink
                    to="/"
                    activeClassName="isActive"
                    isActive={this.checkActive}
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-tachometer"></i>
                      </span>
                      <span className="menuName">Dashboard</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/users?page=1"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-users"></i>
                      </span>
                      <span className="menuName">Users</span>
                    </span>
                  </NavLink>
                </MenuItem>
                {/* vendor category start */}
                {/* sub sidebar practice start */}
                <MenuItem>
                  <span>
                    <SubMenu
                      title="Categories"
                      icon={<i className="fa fa-building"></i>}
                      className="menucirWRp "
                    >
                      <MenuItem>
                        <NavLink
                          to="/vendors-category?page=1"
                          activeClassName="isActive"
                          onClick={() => {
                            this.sidebarToggle();
                          }}
                        >
                          <span className="menucirWRp">
                            <span className="menuIcon">
                              <i className="fa fa-building"></i>
                            </span>
                            <span className="menuName">Vendors Category</span>
                          </span>
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          to="/products-category?page=1"
                          activeClassName="isActive"
                          onClick={() => {
                            this.sidebarToggle();
                          }}
                        >
                          <span className="menucirWRp">
                            <span className="menuIcon">
                              <i className="fa fa-product-hunt"></i>
                            </span>
                            <span className="menuName ">Products Category</span>
                          </span>
                        </NavLink>
                      </MenuItem>
                    </SubMenu>
                  </span>
                </MenuItem>
                {/*sub sidebar practice end */}

                {/* <MenuItem>
                  <NavLink
                    to="/vendors-category?page=1"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-building"></i>
                      </span>
                      <span className="menuName">Vendors Category</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/products-category?page=1"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-product-hunt"></i>
                      </span>
                      <span className="menuName ">Products Category</span>
                    </span>
                  </NavLink>
                </MenuItem> */}

                {/* vendor category end */}

                <MenuItem>
                  <NavLink
                    to="/vendor?page=1"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-shopping-basket"></i>
                      </span>
                      <span className="menuName ">Vendor</span>
                    </span>
                  </NavLink>
                </MenuItem>
                {/* submenu added new Item start */}
                <MenuItem>
                  <span>
                    <SubMenu
                      title="Policies"
                      icon={<i className="fa fa-th"></i>}
                      className="menucirWRp "
                    >
                      <MenuItem className="submenustyling">
                        <NavLink
                          to="/terms-condition"
                          activeClassName="isActive"
                          onClick={() => {
                            this.sidebarToggle();
                          }}
                        >
                          <span className="menucirWRp">
                            <span className="menuIcon ">
                              <i className="fa fa-th"></i>
                            </span>
                            <span className="menuName ">
                              Terms & Conditions
                            </span>
                          </span>
                        </NavLink>
                      </MenuItem>

                      <MenuItem className="submenustyling">
                        <NavLink
                          to="/privacy-policies"
                          activeClassName="isActive"
                          onClick={() => {
                            this.sidebarToggle();
                          }}
                        >
                          <span className="menucirWRp">
                            <span className="menuIcon ">
                              <i className="fa fa-user-secret"></i>
                            </span>
                            <span className="menuName ">Privacy Policies</span>
                          </span>
                        </NavLink>
                      </MenuItem>

                      <MenuItem className="submenustyling">
                        <NavLink
                          to="/cancellation-policies"
                          activeClassName="isActive"
                          onClick={() => {
                            this.sidebarToggle();
                          }}
                        >
                          <span className="menucirWRp">
                            <span className="menuIcon ">
                              <i className="fa fa-ban"></i>
                            </span>
                            <span className="menuName ">
                              Cancellation Policies
                            </span>
                          </span>
                        </NavLink>
                      </MenuItem>
                    </SubMenu>
                  </span>
                </MenuItem>
                {/*submenu added new Item end */}

                <MenuItem>
                  <NavLink
                    to="/orders"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-shopping-cart"></i>
                      </span>
                      <span className="menuName ">Orders</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/admin-accounts"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-credit-card"></i>
                      </span>
                      <span className="menuName ">Admin Accounts</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/drivers"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        {/* <i className="fa fa-th"></i> */}
                        <i className="fa fa-truck"></i>
                      </span>
                      <span className="menuName ">Driver</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/customers"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        {/* <i className="fa fa-th"></i> */}
                        <i className="fa fa-user-circle"></i>
                      </span>
                      <span className="menuName ">Customer</span>
                    </span>
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/coupons"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-money" aria-hidden="true"></i>
                      </span>
                      <span className="menuName ">Coupons</span>
                    </span>
                  </NavLink>
                </MenuItem>

                {/* 
                
                <MenuItem>
                  <NavLink
                    to="/privacy-policies"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        
                        <i className="fa fa-user-secret"></i>
                      </span>
                      <span className="menuName ">Privacy Policies</span>
                    </span>
                  </NavLink>
                </MenuItem>
                
                <MenuItem>
                  <NavLink
                    to="/cancellation-policies"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        <i className="fa fa-ban"></i>
                      </span>
                      <span className="menuName ">Cancellation Policies</span>
                    </span>
                  </NavLink>
                </MenuItem>  */}

                {/* added new Item end*/}
                {/* added new Item start*/}
                <MenuItem>
                  <NavLink
                    to="/faq"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        {/* <i className="fa fa-th"></i> */}
                        <i className="fa fa-question"></i>
                      </span>
                      <span className="menuName ">FAQ's</span>
                    </span>
                  </NavLink>
                </MenuItem>
                {/* added new Item end*/}
                {/* added new Item start*/}
                <MenuItem>
                  <NavLink
                    to="/helpdesk"
                    activeClassName="isActive"
                    onClick={() => {
                      this.sidebarToggle();
                    }}
                  >
                    <span className="menucirWRp">
                      <span className="menuIcon">
                        {/* <i className="fa fa-th"></i> */}
                        <i className="fa fa-info"></i>
                      </span>
                      <span className="menuName ">Help Desk</span>
                    </span>
                  </NavLink>
                </MenuItem>
                {/* added new Item end*/}
                {/* added new Item start*/}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "-550px",
                  }}
                > */}
                <MenuItem className="text-center sidebar-footer">
                  <div
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    V 0.1.15
                  </div>
                </MenuItem>
                {/* </div> */}
                {/* added new Item end*/}
              </Menu>
            </ProSidebar>

            <div className="view-container">
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path="/">
                    <HomePage />
                  </Route>
                  <Route exact path="/login">
                    <HomePage />
                  </Route>
                  <Route path="/settings">
                    <SettingsPage />
                  </Route>

                  <Route exact path="/dashboard">
                    <HomePage />
                  </Route>
                  {/* vendor-Products Routes start */}
                  <Route
                    exact
                    component={Add_View_Vendor_Products}
                    path="/products-by-vendor/view"
                  ></Route>
                  <Route
                    exact
                    component={CouponListPage}
                    path="/coupons"
                  ></Route>
                  <Route
                    exact
                    component={Add_View_Vendor_Products}
                    path="/products-by-vendor/add"
                  ></Route>
                  <Route
                    exact
                    component={Vendor_Products}
                    path="/products-by-vendor"
                  ></Route>
                  <Route exact path="/products-by-vendor/image">
                    <AddImagebyVendorProducts />
                  </Route>
                  {/* vendor-Products Routes end */}

                  <Route component={AddUserPage} path="/users/add"></Route>
                  <Route
                    component={AdminAccount}
                    path="/admin-accounts"
                  ></Route>
                  <Route component={AddUserPage} path="/users/view"></Route>
                  <Route component={UsersPage} path="/users"></Route>
                  <Route
                    component={VendorAccount}
                    path="/vendor-accounts"
                  ></Route>
                  <Route
                    component={VendorAccountsTransactions}
                    path="/vendor-accounts-transaction"
                  ></Route>
                  <Route
                    component={AdminAccountsTransactions}
                    path="/admin-accounts-transaction"
                  ></Route>
                  <Route
                    component={DriverAccountsTransactions}
                    path="/driver-accounts-transaction"
                  ></Route>
                  <Route
                    exact
                    component={HelpDeskListPage}
                    path="/helpdesk/"
                  ></Route>
                  <Route
                    component={HelpDeskAddView}
                    path="/helpdesk/add"
                  ></Route>
                  <Route
                    component={HelpDeskAddView}
                    path="/helpdesk/view"
                  ></Route>
                  <Route
                    component={AddVendorsCategoryPage}
                    path="/vendors-category/view"
                  ></Route>
                  <Route
                    exact
                    component={VendorCategoryPage}
                    path="/vendors-category"
                  ></Route>
                  <Route
                    component={AddVendorsCategoryPage}
                    path="/vendors-category/add"
                  ></Route>
                  <Route
                    component={AddProductsPage}
                    path="/products-category/add"
                  ></Route>
                  <Route
                    component={AddProductsPage}
                    path="/products-category/view"
                  ></Route>
                  <Route
                    exact
                    component={ProductsPage}
                    path="/products-category/"
                  ></Route>
                  <Route
                    component={CustomerAccounts}
                    path="/customer-account"
                  ></Route>
                  <Route
                    exact
                    component={Product_Stock}
                    path="/products-by-vendor/stock"
                  ></Route>
                  <Route
                    exact
                    component={Product_Stock_Update}
                    path="/products-by-vendor/update-stock"
                  ></Route>

                  {/* vendor Routes start */}
                  <Route exact component={VendorPage} path="/vendor/"></Route>
                  <Route
                    exact
                    component={AddVendorsPage}
                    path="/vendor/add"
                  ></Route>
                  <Route
                    exact
                    component={AddVendorsPage}
                    path="/vendor/view"
                  ></Route>
                  <Route exact path="/vendor/profile_image">
                    <AddProfileImageByVendor />
                  </Route>
                  <Route exact path="/vendor/cover_image">
                    <AddCoverImageByVendor />
                  </Route>
                  <Route
                    exact
                    component={AddVendorAddress}
                    path="/vendor/address"
                  ></Route>
                  <Route
                    component={VendorStoreTimings}
                    path="/vendor/storeTimings"
                  ></Route>

                  <Route
                    component={VendorWebPasswordChange}
                    path="/vendor/change_web_password"
                  ></Route>
                  <Route
                    component={VendorAppPasswordChange}
                    path="/vendor/change_app_password"
                  ></Route>
                  {/* vendor Routes end */}

                  <Route
                    exact
                    component={Terms}
                    path="/terms-condition"
                  ></Route>
                  <Route
                    exact
                    component={Privacy}
                    path="/privacy-policies"
                  ></Route>
                  <Route
                    exact
                    component={Cancellation}
                    path="/cancellation-policies"
                  ></Route>
                  {/* faq Routes start */}
                  <Route exact component={FAQ} path="/faq"></Route>
                  <Route exact component={FaqView} path="/faq/view"></Route>
                  <Route exact path="/faq/add">
                    <FaqAdd />
                  </Route>
                  {/* faq Routes end */}

                  {/* Admin Orders Routes start */}
                  <Route exact component={AdminOrders} path="/orders"></Route>

                  {/* Admin Orders Routes end */}

                  {/* Admin Orders Routes start */}
                  <Route
                    exact
                    component={AddViewAdminOrders}
                    path="/orders/view"
                  ></Route>

                  {/* Admin Orders Routes end */}

                  {/* Admin Driver Routes start */}
                  <Route exact component={AdminDrivers} path="/drivers"></Route>
                  <Route
                    exact
                    component={DriversAddView}
                    path="/drivers/view"
                  ></Route>
                  <Route
                    component={DriverAccounts}
                    path="/driver-account"
                  ></Route>
                  {/* <Route component={VendorAccount} path="/vendor-accounts"></Route> */}

                  {/* Admin Driver Routes end */}

                  {/* Admin Customer Routes start */}
                  <Route
                    exact
                    component={AdminCustomers}
                    path="/customers"
                  ></Route>
                  <Route
                    exact
                    component={CustomersAddView}
                    path="/customers/view"
                  ></Route>
                  <Route exact component={Addcoupon} path="/addcoupon"></Route>
                  <Route exact component={Addcoupon} path="/viewCoupon"></Route>
                  {/* Admin Customer Routes end */}
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
