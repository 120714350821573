import React, { useEffect, useState } from "react";
import { useHistory,useLocation  } from "react-router-dom";
// import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./App.css";

// import { BrowserRouter as Router, Route, Switch,Link } from 'react-router-dom';
// import {Preloader} from './components/Preloader';
// import { ErrorPage} from './pages/ErrorPage';

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import { reactLocalStorage } from "reactjs-localstorage";

// let isAuth = false

// function authDone(){
//   console.log('function called')
//   isAuth = true;
// }

function App() {
  
  let history = useHistory();
  // const location = useLocation();
  const [isAuth, authDone] = useState(null);
  useEffect(() => {
    var tokenAccess = reactLocalStorage.get("token");
    var authType = tokenAccess !== undefined ? true : false;
    authDone(authType);
  }, []);

  // console.log(location);
  const logOut = () => {
    reactLocalStorage.clear();
    authDone(false);
  };
  return (
    <div>
      {isAuth && (
        <Dashboard
          setAuth={() => {
            logOut();
          }}
        />
      )}
      {!isAuth &&
        (isAuth !== null ? (
          <Login
            setAuth={() => {
              authDone(true);
            }}
          />
        ) : (
          ""
        ))}
    </div>
  );
}

export default App;
