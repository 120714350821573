import authAxios from "../services/authAxios";
// import UnauthAxios from "../services/unauthAxios";
import ROOT_URL from "./api-url";

// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/a'
// if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){
//     console.log(1);
//     // ROOT_URL ='https://jsonplaceholder.typicode.com'
// }else{
//     // TODO: Server API URL
//     // ROOT_URL ='https://jsonplaceholder.typicode.com'
//     console.log(2);
// }

// ADMIN USER APIS
const apiFunc = {
  getDataUser: (pageData) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/users/pagin`,
      data: pageData,
    }).catch((err) => {
      return Promise.reject(err);
    }),
  postDataUpdate: (pageData) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/users/update/${pageData._id}`,
      data: pageData,
    }).catch((err) => {
      return Promise.reject(err);
    }),
  postDataUser: (userData) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/users/insert`,
      data: userData,
    }).catch((err) => {
      return Promise.reject(err);
    }),
  getDataUserById: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/users/${id}`,
    }).catch((err) => {
      console.log("error is",err)
      return Promise.reject(err);
    }),
    reFreshToken: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/users/token/refresh`,
    }).catch((err) => {
      return Promise.reject(err);
    }),
    getAdminBalance: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/account/balance`,
    }).catch((err) => {
      return Promise.reject(err);
    }),
    adminTransactionPagin: (userData) =>
    authAxios({
      method: "POST",
      data: userData,
      url: `${ROOT_URL}/account/admin/pagin`,
    }).catch((err) => {
      return Promise.reject(err);
    }), 
};

export default apiFunc;
