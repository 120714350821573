import axios from "axios";

import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from "yup";
import "./login.css";
import { useHistory } from "react-router";
import ROOT_URL from "../services/api-url";

const baseURL = ROOT_URL;
export default function Login(props) {
  let history = useHistory();

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter email"),
    password: Yup.string().required("Please enter password"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });
  const login = (userData) => {
    axios
      .post(`${baseURL}/login`, userData)
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
        toast.success(res.data.message);
        props.setAuth();
        // setTimeout(() => {
        //   history.push("/");
        // }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="loginWrapper">
      <ToastContainer />
      <div className="loginboxwrp">
        <div className="lgoboxWrp">
          <h4>Login</h4>
        </div>
        <div className="loginFormWrp">
          <form onSubmit={formik.handleSubmit}>
            <div className="loginGroupField">
              <label>Email</label>
              <div className="fieldGroup">
                <input
                  type="email"
                  {...formik.getFieldProps("email")}
                  className="form-control"
                  placeholder="Enter your username"
                ></input>
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="errorMsg">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="loginGroupField">
              <label>Password</label>
              <div className="fieldGroup">
                <input
                  type="password"
                  {...formik.getFieldProps("password")}
                  className="form-control"
                  placeholder="Enter your username"
                ></input>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="errorMsg">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="loginSubmit">
              <button className="btn-md btn-thm">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
