import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

const authAxios = axios.create();
authAxios.interceptors.request.use((config) => {   

    let token = reactLocalStorage.get('token');
    let headers = {
        'Content-Type': 'application/json',
        'x-access-token':token
    }
    console.log('intersept');
    config.headers = headers; 
    return config;
}, (error) => {
    console.log('reqErrr',error);
    return Promise.reject(error);
});
authAxios.interceptors.response.use((response) => {
    return response;
},(error) => {
    console.log(error.response.data.message)
    return Promise.reject(error);
});
authAxios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
    if(err.response.status === 401){
        reactLocalStorage.clear('token');
        window.location='/login'
    }
    return Promise.reject(err); 
})


export default authAxios;